





















































































































import { Component, Vue, Watch, Ref } from 'vue-property-decorator'
import { LETTER_STATES, Project } from '@/model/Project'
import ProjectDetails from '../../components/core/project/ProjectDetails.vue'
import ProjectFormCard from '../../components/core/project/ProjectFormCard.vue'
import UserDetails from '../../components/core/user/UserDetails.vue'
import LetterForm from '../../components/core/project/LetterForm.vue'
import CgaForm from '../../components/core/project/CgaForm.vue'
import BankDetailsForm from '../../components/core/project/BankDetailsForm.vue'
import PricingForm from '../../components/core/project/PricingForm.vue'
import FiscalMandateForm from '../../components/core/project/FiscalMandateForm.vue'
import DisplayLetter from '../../components/core/project/DisplayLetter.vue'
import router from '@/router'

export type ToggableComponent = Vue & { toggle: (toogled: boolean) => void }
export type CancelableComponent = Vue & { cancel: (noEvent: boolean) => void }

@Component({
  components: {
    ProjectDetails,
    ProjectFormCard,
    UserDetails,
    LetterForm,
    CgaForm,
    BankDetailsForm,
    PricingForm,
    FiscalMandateForm,
    DisplayLetter,
  },
})
export default class ViewProject extends Vue {
  @Ref('editProjectCollapsible')
  readonly editProjectCollapsibleEl!: ToggableComponent
  @Ref('editProject') readonly editProjectEl!: any

  LETTER_STATES = LETTER_STATES

  get project(): Project {
    return this.$store.getters.project
  }

  get mainProject(): Project {
    return this.projects.find(p => p.main) as Project
  }

  get projects(): Project[] {
    return this.$store.getters.projects
  }

  get isAdmin(): boolean {
    return this.$store.getters.isAdmin
  }

  get letter(): string {
    return this.$store.getters.letter
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    const id = +this.$route.params.id
    this.init(id)
  }

  init(id: number) {
    if (!id) {
      id = this.$store.getters.loggedUser.projectIds[0]
    }

    this.$store.dispatch('getProject', id).then(json => {
      if (!json.success) {
        return this.$router.back()
      }
      this.$store.dispatch('getUserProject', id)
    })

    this.$store.dispatch('getProjectImage', id)
    this.$store.dispatch('getProjectLetter', id)
  }

  get projectUser() {
    return this.$store.getters.user
  }

  get user() {
    return this.$store.getters.loggedUser
  }

  loadingLetter = false

  createLetter(data: any) {
    this.loadingLetter = true
    this.$store.dispatch('createLetter', data).then(json => {
      this.loadingLetter = false
      this.$store.dispatch('toaster/toast', json)
      if (json.success) {
        this.mainProject.letterState = LETTER_STATES.CREATED
      }
    })
  }

  updateProject(project: Project) {
    this.$store
      .dispatch('updateProject', {
        id: this.project.id,
        project: project,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        if (json.success) {
          this.editProjectEl.editing = false
        }
      })
  }

  validateLetterUser() {
    this.$store
      .dispatch('validateLetterUser', {
        id: this.project.id,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
      })
  }

  deleteProject() {
    if (confirm('Supprimer la location ?')) {
      this.$store.dispatch('deleteProject', this.project.id).then(json => {
        this.$store.dispatch('toaster/toast', json)
        if (json.success) {
          router.back()
        }
      })
    }
  }

  goToFileHandler() {
    this.$router.push({ name: 'Files' })
  }
  goToAccountingHandler() {
    this.$router.push({ name: 'Accounting' })
  }
  goToLetter() {
    this.$router.push({ name: 'Letter' })
  }
  goToSignLetter() {
    this.$router.push({ name: 'Signing' })
  }
}
